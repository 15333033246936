const colors = {
    // Theme UI colour names
    text: "#111",
    background: "#fff",
    primary: "#490327",
    secondary: "#890046",
    accent: "#490327",
    muted: "#737373",

    // Additional colour names
    bgAccent: "rgba(0,0,0,0.1)",
    bgOpaque: "rgba(152,135,140,0.2)",
}

colors.bgGradient = `linear-gradient(150deg, ${colors.accent} 10%, ${
    colors.text
} 60%, ${colors.text} 100%)`

export default colors